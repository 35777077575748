<template>
  <Layout tituloPagina="Sistema | Ajustes | Facturación">
    <div class="row">
      <div class="col-lg-4">
        <CmpTabs/>
      </div>
    </div>
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Ajustes de facturación
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  Actualizar
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Actualizar
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="$router.go(-1)"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <label>Tipo de renta</label>
            <select v-model="ajustes.id_tipo_renta" class="form-select">
              <option value="1">Renta mensual fija</option>
              <option value="2">Renta mensual variable</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Tipo de cobro</label>
            <select class="form-select" v-model="ajustes.prepago">
              <option value="1">Prepago</option>
              <option value="0">Pospago</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Cargos por mora</label>
            <select class="form-select" v-model="ajustes.cargos_por_mora">
              <option value="0">No</option>
              <option value="1">Si</option>
            </select>
          </div>
          <div class="col-md-3" v-if="ajustes.cargos_por_mora == true">
            <label>Importe de cargos por mora</label>
            <div class="input-group">
              <span class="input-group-text">
                {{ monedaSistema.simbolo }}
              </span>
              <input
                type="number"
                class="form-control text-right"
                min="0"
                v-model="ajustes.importe_cargos_por_mora"
                placeholder="0.00"
              />
              <span class="input-group-text">
                {{ monedaSistema.codigo }}
              </span>
            </div>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-3">
            <label>Meses incluidos en instalación</label>
            <input
              type="number"
              min="0"
              v-model="ajustes.meses_incluidos_en_instalacion"
              @change="
              ajustes.meses_incluidos_en_instalacion < 0
                ? (ajustes.meses_incluidos_en_instalacion = 0)
                : null
              "
              class="form-control text-right"
            />
          </div>
          <div class="col-md-3">
            <label>Precio de instalación</label>
            <div class="input-group">
              <span class="input-group-text">
                {{ monedaSistema.simbolo }}
              </span>
              <input
                type="number"
                v-model="ajustes.precio_instalacion"
                class="form-control text-right"
              />
              <span class="input-group-text">
                {{ monedaSistema.codigo }}
              </span>
            </div>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-3">
            <label>Día de pago</label>
            <div class="input-group">
              <input
                class="valida form-control text-right"
                v-model="ajustes.dia_pago_servicios"
                placeholder="#"
              />
              <span class="input-group-text">
                del mes
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <label>Día de corte</label>
            <div class="input-group">
              <input
                class="valida form-control text-right"
                v-model="ajustes.dias_despues_corte_servicios"
                placeholder="#"
              />
              <span class="input-group-text">
                del mes
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <label>Hora de corte</label>
            <input
              ref="horaCorteServicio"
              type="time"
              class="form-control"
              v-model="ajustes.hora_corte_servicios"
              placeholder="HH:mm"
            >
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-9">
            <label>Dias de la semana para corte</label>
            <multiselect
              v-model="ajustes.dias_semana_cortes"
              placeholder="Buscar dia de la semana"
              selectLabel="Presione enter para seleccionar"
              selectedLabel="Seleccionado"
              deselectLabel="Presione enter para eliminar"
              label="nombre"
              track-by="dia"
              :options="opciones_dias_semana_corte"
              :multiple="true"
              :taggable="false"
            ></multiselect>
          </div>
        </div>

        <br />

        <h5>Fecha de generación de facturas</h5>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <label>Se generarán</label>
            <div class="input-group">
              <input
                class="valida form-control text-right"
                v-model="ajustes.dias_antes_generar_facturas"
                placeholder="N"
              />
              <span class="input-group-text">
                días antes del día de pago
              </span>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <label>Hora de generación</label>
            <div class="input-group">
              <input
                type="time"
                class="horaCorte form-control text-right"
                v-model="ajustes.hora_generar_facturas"
                placeholder="00:00:00"
                step="2" 
              />
              <span class="input-group-text">Hrs.</span>
            </div>
          </div>
        </div>

        <br><br>
        <h5>Establece plazo máximo de prórroga para facturas</h5>
        <div class="row">
          <div class="col-lg-5 col-md-6">
            <div class="input-group">
              <select class="form-select" v-model="ajustes.dias_maximo_prorroga_facturas">
                <option  v-for="n in 90" :value="n" :key="'d-m-p-f-'+n">{{n}}</option>
              </select>
              <span class="input-group-text">
                dias después de la fecha de pago
              </span>
            </div>
          </div>
        </div>

        <br />
        <h5>Usar saldo a favor para el pago de nuevas facturas</h5>

        <div class="text-left">
          <div class="form-check form-switch form-switch-lg">
            <input
              class="form-check-input"
              type="checkbox" id="saldoAFavor"
              :checked="ajustes.aplicar_saldo_favor_a_facturas_pendientes_pago"
              @change="
                ajustes.aplicar_saldo_favor_a_facturas_pendientes_pago = !ajustes.aplicar_saldo_favor_a_facturas_pendientes_pago
              "
            />
            <label class="form-check-label" for="saldoAFavor"></label>
          </div>
          <small>Si la cuenta tiene saldo a favor, será tomado como pago parcial o total automáticamente al generar una factura (factura manual o generada por el sistema).</small>
        </div>

        <!-- <br />
        <h5>Timbrado general</h5>

        <div class="row">
          <div class="col-lg-3 col-md-3">
            <div class="form-check form-switch form-switch-lg">
              <input
                class="form-check-input" checked=""
                type="checkbox" id="timbrado-facturas"
              />
              <label class="form-check-label" for="timbrado-facturas">
                Timbrado
              </label>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import Multiselect from 'vue-multiselect'
import SistemaSrv from '@/services/SistemaSrv.js'
import CmpTabs from './CmpTabs.vue'
export default {
  name: 'Facturacion',
  components: {
    Layout,
    Multiselect,
    CmpTabs
  },
  data() {
    return {
      ajustes: {
        id_tipo_renta: 1,
        prepago: true,
        cargos_por_mora: false,
        importe_cargos_por_mora: 0,
        meses_incluidos_en_instalacion: 0,
        precio_instalacion: 0,
        dia_pago_servicios: 1,
        dias_despues_corte_servicios: 1,
        hora_corte_servicios: "00:00:00",
        dias_semana_cortes: [],
        dias_antes_generar_facturas: 1,
        hora_generar_facturas: "00:00:00",
        dias_maximo_prorroga_facturas: 1,
        aplicar_saldo_favor_a_facturas_pendientes_pago: 0
      },
      opciones_dias_semana_corte: [
        { dia:'Mon', nombre: 'Lunes' },
        { dia:'Tue', nombre: 'Martes' },
        { dia:'Wed', nombre: 'Miércoles' },
        { dia:'Thu', nombre: 'Jueves' },
        { dia:'Fri', nombre: 'Viernes' },
        { dia:'Sat', nombre: 'Sábado' },
        { dia:'Sun', nombre: 'Domingo' }
      ],
      bandera_spinner: false
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function(){
    var self = this

    self.refrescarAjustes()
  },

  methods: {
    actualizar() {
      let self = this,
          ajustes = Object.assign({}, self.ajustes)

      self.bandera_spinner = true

      SistemaSrv.actualizar(ajustes).then(response => {
        iu.msg.success('Datos de facturación actualizados')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron actualizar los datos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    refrescarAjustes() {
      let self = this,
          ajustesSolicitados = Object.keys(self.ajustes)

      SistemaSrv.ajustes(ajustesSolicitados).then(response => {
        let ajustes = response.data
        Object.assign(self.ajustes, ajustes)
        self.ajustes.dias_semana_cortes = JSON.parse(ajustes.dias_semana_cortes)
        self.ajustes.aplicar_saldo_favor_a_facturas_pendientes_pago = response.data.aplicar_saldo_favor_a_facturas_pendientes_pago == "0" ? false : true
      })
    }
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>