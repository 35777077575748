<template>
  <div class="mb-4">
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item waves-effect waves-light">
        <router-link
          class="nav-link"
          :class="{ 
            active: $route.name == 'ajustesFacturacionSistema'
          }"
          :to="{ name: 'ajustesFacturacionSistema' }"
        >
          General
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'plantillaFacturaSistema' }"
          class="nav-link"
          :class="{ 
            active: $route.name=='plantillaFacturaSistema'
          }"
        >
          Plantilla
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CmpTabs'
}
</script>